import React from "react";
import styled from "styled-components";

type Props = {
  text: string;
};

const Link = styled.a`
  margin: 0 0.5rem;
  color: white;
  letter-spacing: 0.06em;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const NavBarItem = ({ text }: Props) => {
  return <Link href={`#${text}`}>{text}</Link>;
};

export default NavBarItem;
