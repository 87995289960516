import React from "react";
import styled from "styled-components";
import { theme } from "../theme";

const Container = styled.section`
  background-color: ${theme.colors.blueberry};
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Signature = styled.span`
  color: white;
  font-weight: 300;
`;

const Footer = () => {
  return (
    <Container>
      <Signature>© 2023 Alex Kawaschinski</Signature>
    </Container>
  );
};

export default Footer;
