import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "../theme";

type Props = {
  images: string[];
  isApp: boolean;
};

const Wrapper = styled.div`
  //   width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Images = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  position: relative;
  //   transform: translateX(-33%);
`;

const AppImage = styled.img<{
  active: boolean;
  isLeft: boolean;
  isRight: boolean;
}>`
  border-radius: 25px;
  position: absolute;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: transform 0.5s 0.25s, opacity 0.33s 0.25s;
  opacity: 0;

  ${({ active }) =>
    active &&
    `
  transform: translateX(0);
  opacity: 1;
  position: relative;
  `}

  ${({ isLeft }) =>
    isLeft &&
    `
    transform: translateX(-10%);
    transition: transform 0.5s, opacity 0.33s;
  `}

  ${({ isRight }) =>
    isRight &&
    `
    transform: translateX(10%);
    transition: transform 0.5s, opacity 0.33s;

    `}
`;

const WebImage = styled.img<{
  active: boolean;
  isLeft: boolean;
  isRight: boolean;
}>`
  width: 85%;
  left: 50%;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: transform 0.5s 0.25s, opacity 0.33s 0.25s;
  opacity: 0;

  ${({ active }) =>
    active &&
    `
  transform: translateX(-50%);
  opacity: 1;
  position: relative;
  `}

  ${({ isLeft }) =>
    isLeft &&
    `
    transform: translateX(-40%);
    transition: transform 0.5s, opacity 0.33s;
  `}

  ${({ isRight }) =>
    isRight &&
    `
    transform: translateX(-60%);
    transition: transform 0.5s, opacity 0.33s;

    `}
`;

const Pager = styled.div`
  display: flex;
`;

const Dot = styled.div<{ active: boolean }>`
  height: 15px;
  width: 15px;
  margin: 0 0.5rem;
  background-color: ${(props) => (props.active ? "#333" : "white")};
  border-radius: 50%;
  transition: background-color 0.25s;
`;

const Carousel = ({ images, isApp }: Props) => {
  const [activeIndex, setActiveIndex] = useState(1);

  useEffect(() => {
    if (!images) {
      return;
    }

    const startDelay = Math.floor(Math.random() * 1000);

    let intervalId: any = null;

    const nextPage = () => {
      intervalId = setInterval(() => {
        setActiveIndex(activeIndex === images.length - 1 ? 0 : activeIndex + 1);
      }, 5000);
    };

    setTimeout(nextPage, startDelay);

    return () => clearInterval(intervalId);
  }, [images, activeIndex]);

  const getImage = (image: string, i: number) =>
    isApp ? (
      <AppImage
        src={image}
        alt="travelspend-preview"
        active={activeIndex === i}
        isLeft={activeIndex > i}
        isRight={activeIndex < i}
      />
    ) : (
      <WebImage
        src={image}
        alt="travelspend-preview"
        active={activeIndex === i}
        isLeft={activeIndex < i}
        isRight={activeIndex > i}
      />
    );

  return (
    <Wrapper>
      <Images>{images.map((image, i) => getImage(image, i))}</Images>
      <Pager>
        {images.map((img, i) => (
          <Dot active={activeIndex === i} />
        ))}
      </Pager>
    </Wrapper>
  );
};

export default Carousel;
