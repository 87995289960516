import React from "react";
import NavBar from "./components/NavBar";
import styled from "styled-components";
import { theme } from "./theme";
import Page from "./components/Page";
import Footer from "./components/Footer";

const Wrapper = styled.div`
  width: 100vw;
  height: 100%;
  margin: 0;
`;

const PAGES = [
  {
    key: "profile",
    color: theme.colors.grass,
    title: "ME",
  },
  {
    key: "projects",
    color: theme.colors.mint,
    title: "PROJECTS",
  },
  {
    key: "contact",
    color: theme.colors.ocean,
    title: "CONTACT",
  },
];

const App = () => {
  return (
    <Wrapper>
      <NavBar />
      <>
        {PAGES.map(({ key, color, title }) => (
          <Page key={key} contentKey={key} color={color} title={title} />
        ))}
      </>
      <Footer />
    </Wrapper>
  );
};

export default App;
