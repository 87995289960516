export const theme = {
  colors: {
    text: "#333333",
    grass: "#C1EDCC",
    mint: "#8FDBC5",
    ocean: "#64ADC4",
    blueberry: "#367096",
  },
  tablet: "min-width: 768px",
};
