import React from "react";
import styled from "styled-components";
import NavBarItem from "./NavBarItem";
import { theme } from "../theme";

const NAV_ITEMS: string[] = ["profile", "projects", "contact"];

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 1.5rem;
  background-color: ${theme.colors.blueberry};
  height: 3.5rem;
  background-color: rgba(33, 33, 33, 0.5);
  backdrop-filter: blur(20px);
  z-index: 999;

  @media (${theme.tablet}) {
    justify-content: center;
  }
`;

const NavBar = () => {
  return (
    <Wrapper>
      {NAV_ITEMS.map((item) => (
        <NavBarItem text={item} />
      ))}
    </Wrapper>
  );
};

export default NavBar;
