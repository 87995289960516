import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Headshot from "../../assets/headshot.jpg";
import { theme } from "../../theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  scroll-margin-top: 3.5rem;

  @media (${theme.tablet}) {
    flex-direction: row;
    max-width: 1080px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  min-height: 3rem;
`;

const Subtitle = styled.p`
  text-wrap: wrap;
  line-height: 1.4rem;
  letter-spacing: 0.04em;

  @media (${theme.tablet}) {
    max-width: 420px;
    margin-right: 1rem;
  }
`;

const Avatar = styled.div`
  height: 300px;
  min-width: 300px;
  border-radius: 100%;
  background-color: white;
  margin: auto;
  margin-bottom: 2rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  @media (${theme.tablet}) {
    order: 1;
  }
`;

const Profile = () => {
  const [headlines] = useState([
    "Problem solver...",
    "Critical thinker...",
    "Happy to help...",
  ]);

  const [speed, setSpeed] = useState(75);
  const [headlineIndex, setHeadlineIndex] = useState(0);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index <= headlines[headlineIndex].length) {
      const timeoutId = setTimeout(() => {
        // Slow down typing at the last few characters
        if (index + 4 === headlines[headlineIndex].length) {
          setSpeed(550);
        }

        if (index === headlines[headlineIndex].length) {
          const nextHeadlineIndex =
            headlineIndex + 1 === headlines.length ? 0 : headlineIndex + 1;
          setHeadlineIndex(nextHeadlineIndex);
          setIndex(0);
          setSpeed(75);
          return;
        }

        setIndex(index + 1);
      }, speed);
      return () => clearTimeout(timeoutId);
    }
  }, [index, speed, headlines, headlineIndex]);

  return (
    <Wrapper id="profile">
      <Avatar style={{ backgroundImage: `url(${Headshot})` }} />
      <Content>
        <Title>{headlines[headlineIndex].slice(0, index)}</Title>
        <Subtitle>
          Hey I'm Alex Kawaschinski, a mobile and web developer based in Berlin.
          I strive to develop future proof applications while solving problems
          for the everyday person.
        </Subtitle>
      </Content>
    </Wrapper>
  );
};

export default Profile;
