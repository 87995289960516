import React from "react";
import styled from "styled-components";

import { Mail, LinkedIn, GitHub } from "@material-ui/icons";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  justify-content: center;
  scroll-margin-top: 3.5rem;
`;

const Title = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: white;
`;

const Subtitle = styled.p`
  margin-bottom: 1rem;
  letter-spacing: 0.06em;
  color: white;
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  text-decoration: none;
  color: white;
`;

const Text = styled.span`
  padding-left: 0.5rem;
  color: white;
`;

const Contact = () => {
  return (
    <Wrapper id="contact">
      <Title>Got a problem to solve?</Title>
      <Subtitle>
        I'm always happy to help. Please see my contact info below and feel free
        to message me.
      </Subtitle>

      <Link href="mailto:alexkawa.berlin@gmail.com">
        <Mail />
        <Text>alexkawa.berlin@gmail.com</Text>
      </Link>

      <Link
        href="https://www.linkedin.com/in/alex-kawaschinski-19a400176"
        target="_blank"
      >
        <LinkedIn />
        <Text>LinkedIn</Text>
      </Link>

      <Link href="https://github.com/alexkawa" target="_blank">
        <GitHub />
        <Text>Github</Text>
      </Link>
    </Wrapper>
  );
};

export default Contact;
