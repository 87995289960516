import React from "react";
import styled from "styled-components";
import Profile from "./pages/Profile";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";

type Props = {
  contentKey: string;
  color: string;
  title: string;
};

const Wrapper = styled.section`
  width: 100%;
  min-height: calc(100vh - 4rem);
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:nth-of-type(1) {
    background: linear-gradient(
      180deg,
      rgba(193, 237, 204, 1) 0%,
      rgba(143, 219, 197, 1) 100%
    );
  }

  &:nth-of-type(2) {
    background: linear-gradient(
      180deg,
      rgba(143, 219, 197, 1) 0%,
      rgba(100, 174, 196, 1) 100%
    );
  }
`;

const Title = styled.h4`
  color: black;
`;

const Page = ({ contentKey, color, title }: Props) => {
  const renderContent = () => {
    console.log(contentKey);
    switch (contentKey) {
      case "profile":
        return <Profile />;
      case "projects":
        return <Projects />;
      case "contact":
        return <Contact />;
    }
  };
  return (
    <Wrapper style={{ backgroundColor: color }}>
      {/* <Title>{title}</Title> */}
      {renderContent()}
    </Wrapper>
  );
};

export default Page;
