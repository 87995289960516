import React from "react";
import styled from "styled-components";
import Project, { TProject } from "../Project";

import TravelSpendPreview1 from "../../assets/travelspend-preview-1.png";
import TravelSpendPreview2 from "../../assets/travelspend-preview-2.png";
import TravelSpendPreview3 from "../../assets/travelspend-preview-3.png";
import _202upPreview1 from "../../assets/202up-preview-1.png";
import _202upPreview2 from "../../assets/202up-preview-2.png";
import _202upPreview3 from "../../assets/202up-preview-3.png";
import FlatfinderPreview1 from "../../assets/flatfinder-preview-1.png";
import FlatfinderPreview2 from "../../assets/flatfinder-preview-2.png";
import FlatfinderPreview3 from "../../assets/flatfinder-preview-3.png";

const PROJECTS: TProject[] = [
  {
    key: "travelspend",
    title: "TravelSpend",
    platform: "app",
    images: [TravelSpendPreview1, TravelSpendPreview2, TravelSpendPreview3],
    description:
      "I joined the TravelSpend team a couple of months before their planned re-release of the app. Building screens for filtering/searching, a map with markers and clusters, calculation of debts between users, csv exports, optional notifications and much more. ",
    techstack: [
      "React Native (Expo)",
      "React-Native-Maps & Supercluster",
      "React Context",
      "JS-Coroutines",
      "Notifee",
    ],
  },
  {
    key: "flatfinder",
    title: "Flatfinder",
    platform: "website",
    images: [FlatfinderPreview1, FlatfinderPreview2, FlatfinderPreview3],
    description:
      "Flatfinder is a responsive React-based website designed to assist users in finding their ideal flat.\n\nDeveloped with an interactive canvas element that showcases an intricately designed 3D house and its various apartments, Flatfinder offers users the ability to filter, favorite, and view detailed information about individual apartments.\n\nAll data and the color theme changes depending on the project id given in the URL.",
    techstack: [
      "React",
      "Typescript",
      "Redux Toolkit",
      "Axios",
      "Styled-Components",
    ],
  },
  {
    key: "202up",
    title: "202up",
    platform: "website",
    images: [_202upPreview1, _202upPreview2, _202upPreview3],
    description:
      "202up.com is a static, responsive website built as a company homepage. ",
    techstack: ["Middleman (Ruby)", "Haml", "Sass", "Webpack", "Babel"],
  },
];

const Wrapper = styled.div`
  scroll-margin-top: 3.5rem;
  width: 100%;
`;

const Projects = () => {
  return (
    <Wrapper id="projects">
      {PROJECTS.map((data, i) => (
        <Project key={data.key} animateFromRight={i % 2 === 0} data={data} />
      ))}
    </Wrapper>
  );
};

export default Projects;
