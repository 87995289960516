import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import useOnScreen from "../utils/hooks";
import { theme } from "../theme";
import Carousel from "./Carousel";

type Platform = "app" | "website";

export type TProject = {
  key: string;
  title: string;
  platform: Platform;
  images: string[];
  description: string;
  techstack: string[];
};

type Props = {
  data: TProject;
  animateFromRight: boolean;
};

const Wrapper = styled.div`
  padding-bottom: 5rem;
  position: relative;
  width: 100%;
  overflow: hidden;

  &:nth-child(1) {
    background: linear-gradient(
      180deg,
      rgba(100, 174, 196, 1) 0%,
      rgba(143, 219, 197, 1) 100%
    );
  }
  &:nth-child(2) {
    background: linear-gradient(
      180deg,
      rgba(143, 219, 197, 1) 0%,
      rgba(100, 174, 196, 1) 100%
    );
  }
  &:nth-child(3) {
    background: linear-gradient(
      180deg,
      rgba(100, 174, 196, 1) 0%,
      rgba(143, 219, 197, 1) 100%
    );
  }

  @media (${theme.tablet}) {
    display: flex;
    justify-content: center;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (${theme.tablet}) {
    flex-direction: row;
    max-width: 1080px;
    flex-wrap: wrap;
  }
`;

// rgba(54, 112, 150, 1) 100%

const TitleContainer = styled.div`
  background-color: white;
  width: 100%;
  margin-bottom: 1.5rem;
  position: relative;
  height: 3rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  @media (${theme.tablet}) {
    &::before {
      content: "";
      height: 100%;
      width: 4.5rem;
      position: absolute;
      left: 0;
      transform: translateX(-100%);
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &::after {
      content: "";
      height: 100%;
      width: 4.5rem;
      position: absolute;
      right: 0;
      transform: translateX(100%);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
`;

const Title = styled.h2<{
  visible: boolean;
  animateFromRight: boolean;
}>`
  font-size: 2rem;
  text-align: center;
  padding: 0.25rem 0;
  letter-spacing: 0.12em;
  position: absolute;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  left: ${(props) =>
    props.visible ? "50%" : props.animateFromRight ? "55%" : "45%"};
  transform: translateX(-50%);
  transition: left 0.7s, opacity 0.75s;
`;

const ImageContainer = styled.div<{
  visible: boolean;
  animateFromRight: boolean;
}>`
  width: 100%;
  margin: 1.5rem 0;
  position: relative;
  transition: opacity 0.75s, transform 0.75s;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: translateX(${(props) => (props.visible ? "0" : "5%")});

  @media (${theme.tablet}) {
    max-width: 500px;
    order: ${(props) => (props.animateFromRight ? 0 : 1)};
  }
`;

const ContentContainer = styled.div<{
  visible: boolean;
  animateFromRight: boolean;
}>`
  position: relative;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  left: ${(props) =>
    props.visible ? "50%" : !props.animateFromRight ? "55%" : "45%"};
  transform: translateX(-50%);
  transition: left 0.7s, opacity 0.75s;

  @media (${theme.tablet}) {
    flex: 1;
    transform: translateX(0);
    left: ${(props) =>
      props.visible ? "0" : !props.animateFromRight ? "5%" : "-5%"};
  }
`;

const Description = styled.p`
  padding: 0 2rem;
  margin-bottom: 1rem;
  font-size: 1.12rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 1.3rem;
  color: white;
  white-space: pre-wrap;
`;

const List = styled.ul`
  padding: 0 3rem;
  width: 100%;
`;

const ListItem = styled.li`
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: white;
`;

const AppImage = styled.img<{
  visible: boolean;
  animateFromRight: boolean;
}>`
  width: 60%;
  border-radius: 25px;
  position: relative;
  left: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  left: ${(props) =>
    props.visible ? "50%" : !props.animateFromRight ? "55%" : "45%"};
  transform: translateX(-50%);
  transition: left 0.7s, opacity 0.75s;

  @media (${theme.tablet}) {
    left: ${(props) =>
      props.visible ? "0" : !props.animateFromRight ? "5%" : "-5%"};
    transform: translateX(0);
  }
`;

const WebImage = styled.img<{
  visible: boolean;
  animateFromRight: boolean;
}>`
  width: 85%;
  position: relative;
  left: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;image.png
  opacity: ${(props) => (props.visible ? 1 : 0)};
  left: ${(props) =>
    props.visible ? "50%" : !props.animateFromRight ? "55%" : "45%"};
  transform: translateX(-50%);
  transition: left 0.7s, opacity 0.75s;

  @media (${theme.tablet}) {
    left: ${(props) =>
      props.visible ? "0" : !props.animateFromRight ? "5%" : "-5%"};
    transform: translateX(0);
  }
`;

const Project = ({ data, animateFromRight }: Props) => {
  const titleRef: any = useRef(null);
  const imageRef: any = useRef(null);
  const descriptionRef: any = useRef(null);
  const titleVisible = useOnScreen(titleRef);
  const imageVisible = useOnScreen(imageRef);
  const descriptionVisible = useOnScreen(descriptionRef);

  const { title, platform, images, description, techstack } = data;

  // const getImage = () =>
  //   platform === "app" ? (
  //     <AppImage
  //       src={image}
  //       alt="travelspend-preview"
  //       visible={imageVisible}
  //       animateFromRight={animateFromRight}
  //     />
  //   ) : (
  //     <WebImage
  //       src={image}
  //       alt="travelspend-preview"
  //       visible={imageVisible}
  //       animateFromRight={animateFromRight}
  //     />
  //   );

  return (
    <Wrapper>
      <Container>
        <TitleContainer ref={titleRef}>
          <Title visible={titleVisible} animateFromRight={animateFromRight}>
            {title}
          </Title>
        </TitleContainer>
        <ImageContainer
          ref={imageRef}
          visible={imageVisible}
          animateFromRight={animateFromRight}
        >
          {/* {getImage()} */}
          <Carousel images={images} isApp={platform === "app"} />
        </ImageContainer>
        <ContentContainer
          ref={descriptionRef}
          visible={descriptionVisible}
          animateFromRight={!animateFromRight}
        >
          <Description>{description}</Description>
          <List>
            {techstack.map((item) => (
              <ListItem key={item}>{item}</ListItem>
            ))}
          </List>
        </ContentContainer>
      </Container>
    </Wrapper>
  );
};

export default Project;
